import { useNavigate } from "react-router-dom"
import './index.scss'

import chevronLeft from '@icon/chevron.left.svg';

interface HeaderLink {
  icon?: any
  title: string
  onClick: () => void
}

export default function PageHeader(props: {
  title: string,
  subtitle?: string,
  links?: HeaderLink[],
  backLocation?: string,
  showBackButton?: boolean
}) {
  const navigate = useNavigate();

  return <div className="page-header">
    {props.showBackButton === undefined || props.showBackButton === true ?
      <a className="back" href="#back" onClick={() => navigate(props.backLocation ?? "/")}><img className="icon" src={chevronLeft} alt="Left arrow icon" /> Back</a> :
      null}

    <h1 className="header-title">{props.title}</h1>
    {props.subtitle !== undefined ? <h2 className="header-subtitle">{props.subtitle}</h2> : null}
    {props.links !== undefined ? <div className="header-links">
      {props.links.map((link) => {
        return <a href={link.title}
          key={link.title}
          onClick={(e) => {
            e.preventDefault();
            link.onClick();
          }}>
          {link.icon !== undefined ? <img src={link.icon} alt={`${link.title} icon`} /> : null}{link.title}
        </a>
      })}
    </div> : null}
  </div>
}
import Sidebar from "@components/Sidebar";
import { useChurchDataProvider } from "@data/ChurchDataProvider";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";

export default function Appv2() {
  const churchData = useChurchDataProvider();

  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

  return <div className="Appv2" style={{
    display: 'flex'
  }}>
    <Sidebar churchName={churchData.church?.name ?? ""} isMobile={isMobile} />
    <div style={{
      marginTop: isMobile ? '80px' : '0',
      width: '100%',
      overflow: 'hidden'
    }}>
      <Outlet />
    </div>
  </div>
}
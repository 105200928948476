import { useAuth } from "@auth/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

import logo from '@image/HeaderLogo.png'
import logoDark from '@image/HeaderLogo-dark.png'
import useDarkMode, { Theme } from "@hooks/useDarkMode";

import backgroundImage from '@image/TestBG.jpg'

import './index.scss'
import { useState } from "react";

enum Context {
  LOGIN,
  REQUEST_RESET
}

export default function LoginPage(props: {}) {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const [performingAction, setPerformingAction] = useState(false);
  const [context, setContext] = useState<Context>(Context.LOGIN);

  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const theme = useDarkMode();

  let from = location.state?.from?.pathname || '/';

  async function handleSubmit() {
    if (performingAction) return;

    if (context === Context.LOGIN) {
      submitLogin();
    } else {
      submitReset();
    }
  }

  async function submitLogin() {
    if (email.length === 0) {
      setError("Please enter your email.");
      return;
    }

    if (password.length === 0) {
      setError("Please enter your password.");
      return;
    }

    setError("");
    setPerformingAction(true);

    try {
      await auth.signIn(email, password);
      navigate(from, { replace: true });
    } catch (error: any) {
      setError(error.message);
      setPerformingAction(false);
    }
  }

  async function submitReset() {
    if (email.length === 0) {
      setError("Please enter your email.");
      return;
    }

    setError("");
    setPerformingAction(true);

    try {
      const message = await auth.requestPasswordReset(email);
      alert(message);
    } catch (error: any) {
      setError(error.message);
    }

    setPerformingAction(false);
  }

  async function handleContextChangeButtonClick() {
    if (performingAction) return;

    setError("");
    setContext(context === Context.LOGIN ? Context.REQUEST_RESET : Context.LOGIN);
  }

  return <div className="login-screen">
    {theme === Theme.DARK ? <div className="opacity-layer" /> : null}

    <div className="login-form">
      <img src={theme === Theme.LIGHT ? logo : logoDark}
        draggable={false}
        alt="Salt Logo" />

      {error !== "" ? <p className="error">{error}</p> : ""}

      <p style={{
        textAlign: 'center'
      }}>
        {context === Context.LOGIN ?
          "Please use your Salt Church Hub credentials to login." :
          "Please enter your email to reset your password for your Salt Church Hub account."
        }
      </p>

      <div className="input-holder">
        <input type="email"
          placeholder="Email"
          value={email}
          disabled={performingAction}
          onChange={(e) => {
            setEmail(e.target.value);
            setError("");
          }} />

        {context === Context.LOGIN ? <input type="password"
          placeholder="Password"
          value={password}
          disabled={performingAction}
          onChange={(e) => {
            setPassword(e.target.value);
            setError("");
          }} /> : null}
      </div>

      <button className={`login ${performingAction ? "disabled" : ""}`}
        disabled={performingAction}
        onClick={handleSubmit}>{context === Context.LOGIN ? "Login" : "Request Reset"}</button>

      <button className="forgot"
        disabled={performingAction}
        onClick={handleContextChangeButtonClick}>{context === Context.LOGIN ? "Forgot Password?" : "Login Instead"}</button>

      {/* <p>Not using Salt yet? <a href="https://saltplatform.com/contact-us?ref=hublogin" target="_blank" rel="noreferrer">Let's chat!</a></p> */}
    </div>
  </div>
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Route, Routes } from 'react-router';

import { BrowserRouter } from "react-router-dom";

import AuthProvider from '@auth/AuthProvider'
import RequireAuth from '@auth/RequireAuth'

import ChurchDataProvider from '@data/ChurchDataProvider'

import Dashboard from '@pages/Dashboard';

import CheckInHub from '@pages/CheckInHub'
import TeamManager from '@pages/TeamManager'
import NewsletterManager from '@pages/NewsletterManager'
import ChurchManager from '@pages/ChurchManager';
import MinistryDataPage from '@pages/MinistryData'

import Playlists from '@pages/v2/Playlists';
import Welcome from '@pages/v2/Welcome';

import LoginPage from '@pages/LoginPage';

import './index.scss';
import '@styles/modal.scss'

import Modal from 'react-modal'
import ChurchConnections from '@pages/ChurchConnections';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import MinistryDataDetail from '@pages/MinistryData/Detail';

// MARK: - V2

import Appv2 from './Appv2'

Modal.setAppElement('#root');

ReactDOM.render(
  <>
    <BrowserRouter>
      <AuthProvider>
        <ChurchDataProvider>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Appv2 />}>
                <Route index element={<Welcome />} />
                <Route path="home" element={<Welcome />} />
                <Route path="service-plans" element={<MinistryDataPage />} />
                <Route path="playlists" element={<Playlists />} />
                <Route path="team" element={<TeamManager />} />

                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                />
              </Route>
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Routes>
        </ChurchDataProvider>
      </AuthProvider>
    </BrowserRouter>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState } from 'react';

import PageHeader from '@components/PageHeader'

import plusIcon from '@icon/plus.svg'

import './index.scss'
import ChurchMinistryData from '@data/interfaces/ministrydata.interface';
import { useChurchDataProvider } from '@data/ChurchDataProvider';

import { Link, useNavigate } from 'react-router-dom';
import DeleteServicePlanModal from './Modal/DeletePlanModal';

export default function WeekData() {
  const churchData = useChurchDataProvider();

  const navigate = useNavigate();

  const [attemptedLoad, setAttemptedLoad] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(undefined);

  const [planToDelete, setPlanToDelete] = useState<ChurchMinistryData | undefined>(undefined);

  useEffect(() => {
    if (!attemptedLoad) {
      setAttemptedLoad(true);

      churchData.loadMinistryData().catch((error: Error) => {
        setLoadingError(error.message);
      });
    }
  }, [churchData, attemptedLoad]);

  return <div className="week-data-container container">
    <PageHeader title="Service Plans" showBackButton={false}
      links={[
        {
          title: "Create Service Plan",
          icon: plusIcon,
          onClick: () => {
            navigate("/service-plans/new")
          }
        }
      ]} />

    <DeleteServicePlanModal
      churchId={churchData.church?.id}
      plan={planToDelete}
      isOpen={planToDelete !== undefined}
      setIsOpen={() => {
        churchData.loadMinistryData().catch((error: Error) => {
          setLoadingError(error.message);
        });
        setPlanToDelete(undefined);
      }} />

    {loadingError ? <p className='error'>{loadingError}</p> : null}

    <div className="card-holder">
      {churchData.ministryData.map((data: ChurchMinistryData) => {
        return <div className="card" key={data.id}>
          <p className={`status ${data.status.toLowerCase()}`}>
            {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
          </p>
          <div className={`status-bar ${data.status.toLowerCase()}`} />

          <h3 className="subtitle">{data.ministry} / {data.subministry ?? "No Subministry"}</h3>
          <h1>{data.sermonMetadata?.sermonTitle ?? "No Sermon"}</h1>
          <p>{data.worshipSongs.length} worship song{data.worshipSongs.length === 1 ? "" : "s"}</p>

          <p className="secondary">Active from <span>{data.startDate}</span> to <span>{data.endDate}</span>.</p>
          <span>
            <Link to={`/service-plans/${data.ministry}/${data.id}`}>Edit</Link>
            <a href="#delete" onClick={() => {
              setPlanToDelete(data);
            }}>Delete</a>
          </span>
        </div>
      })}
    </div>
  </div>
}
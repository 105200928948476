import { useAuth } from "@auth/AuthProvider";
import React, { createContext, ReactNode, useCallback, useEffect, useState } from "react";

import { Church, ChurchStaff, StaffAction, SupportedChurchVideoService } from '@interfaces/church.interfaces'
import { CheckInRegion, CheckInReport } from "./interfaces/checkin.interfaces";

import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController'
import { useNavigate } from "react-router-dom";
import ChurchMinistryData from "./interfaces/ministrydata.interface";

interface ChurchFetchResponse {
  church: Church,
  regions: CheckInRegion[],
  staff: ChurchStaff[],
  connectionsMetadata?: ConnectionsMetadata
}

export interface ConnectionsMetadata {
  supportedVideoUploadServices: SupportedChurchVideoService[]
  facebookPageName?: string
  googleAccountName?: string
}

interface ChurchCheckInFetchResponse {
  results: CheckInReport[]
}

interface ChurchDataProviderType {
  church?: Church;
  regions: CheckInRegion[];
  staff: ChurchStaff[];
  checkInReports: CheckInReport[];
  totalCheckIns: number;
  connectionsMetadata?: ConnectionsMetadata
  ministryData: ChurchMinistryData[];
  loadMinistryData: () => Promise<ChurchMinistryData[]>;
  reloadChurch: () => void;
  reloadCurrentWeekCheckInData: (churchId: string) => void;
  clear: () => void
}

let ChurchDataContext = createContext<ChurchDataProviderType>(null!);

export default function ChurchDataProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const navigate = useNavigate();

  let [church, setChurch] = useState<Church | undefined>(undefined);
  let [regions, setRegions] = useState<CheckInRegion[]>([]);
  let [staff, setStaff] = useState<ChurchStaff[]>([]);
  let [checkInReports, setCheckInReports] = useState<CheckInReport[]>([]);
  let [totalCheckIns, setTotalCheckIns] = useState<number>(0);
  let [connectionsMetadata, setConnectionsMetadata] = useState<ConnectionsMetadata | undefined>(undefined);
  let [ministryData, setMinistryData] = useState<ChurchMinistryData[]>([]);

  const reloadCurrentWeekCheckInData = useCallback(async (churchId: string) => {
    if (!auth.hasPermission(StaffAction.VIEW_CHECK_IN_STATS)) return;

    const response = await NetworkController.performRequest<ChurchCheckInFetchResponse>(
      new HTTPRequest(HTTPMethod.GET, `/church/${churchId}/check-in/stats`),
      auth.consumeRefreshToken
    );

    if (response.error || !response.body) {
      return console.error(response.error);
    }

    setCheckInReports(response.body.results)
  }, [auth])

  const reloadChurch = useCallback(async (churchId: string) => {
    const response = await NetworkController.performRequest<ChurchFetchResponse>(
      new HTTPRequest(HTTPMethod.GET, `/church/${churchId}?includeStaff=true&includeConnectionMetadata=true`),
      auth.consumeRefreshToken
    );

    if (response.error || !response.body) {
      return auth.signOut(() => {
        navigate("/")
      });
    }

    const { church, staff, regions, connectionsMetadata } = response.body;

    setChurch(church)
    setStaff(staff === undefined ? [] : staff)
    setRegions(regions)
    setConnectionsMetadata(connectionsMetadata);

    // reloadCurrentWeekCheckInData(churchId);
  }, [auth, navigate])

  const loadMinistryData = useCallback(async () => {
    const response = await NetworkController.performRequest<{ results: ChurchMinistryData[] }>(
      new HTTPRequest(HTTPMethod.GET, `/church/${church?.id}/ministry-data`),
      auth.consumeRefreshToken
    );

    if (response.error || !response.body) {
      throw new Error(response.error ?? "Failed to load service plans. Please try again later.");
    }

    const data = response.body.results;
    setMinistryData(data);
    return data;
  }, [auth.consumeRefreshToken, church?.id]);

  const clear = () => {
    setChurch(undefined)
    setRegions([])
    setStaff([])
    setCheckInReports([])
    setConnectionsMetadata(undefined);
    setMinistryData([]);
  }

  // Modify data when user changes
  useEffect(() => {
    if (auth.user !== undefined && auth.churchId !== undefined) {
      reloadChurch(auth.churchId)
    } else {
      clear();
    }
  }, [auth.user, reloadChurch, auth.churchId]);

  useEffect(() => {
    setTotalCheckIns(checkInReports.map(report => report.count).reduce((one, two) => {
      return one + two
    }, 0));
  }, [checkInReports]);

  let value = { church, regions, staff, checkInReports, totalCheckIns, connectionsMetadata, reloadChurch: () => {
    if (!auth.user || !auth.churchId) return;
    reloadChurch(auth.churchId);
  }, reloadCurrentWeekCheckInData, loadMinistryData, ministryData, clear };

  return <ChurchDataContext.Provider value={value}>{children}</ChurchDataContext.Provider>;
}

export function useChurchDataProvider() {
  return React.useContext(ChurchDataContext);
}
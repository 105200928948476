import useDarkMode, { Theme } from '@hooks/useDarkMode'
import './index.scss'

import logo from '@assets/images/HeaderLogo.png'
import logoDark from '@assets/images/HeaderLogo-dark.png'

import icon from '@assets/icons/house.svg'
import servicePlansIcon from '@assets/icons/persontime.svg'
import playlistsIcon from '@assets/icons/music.list.svg'
import rightArrowIcon from '@assets/icons/arrow.right.svg'
import teamIcon from '@icon/person.svg'
import sidebarIcon from '@icon/sidebar.svg'

import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@auth/AuthProvider'

import { slide as Menu } from 'react-burger-menu'
import { useState } from 'react'
import { StaffAction } from '@data/interfaces/church.interfaces'

type Route = {
  path: string;
  name: string;
  icon: string;
  requiredPermission: StaffAction | null;
}

const routes: Route[] = [
  {
    path: 'home',
    name: 'Home',
    icon: icon,
    requiredPermission: null
  },
  {
    path: 'service-plans',
    name: 'Service Plans',
    icon: servicePlansIcon,
    requiredPermission: StaffAction.CREATE_MINISTRY_DATA
  },
  {
    path: 'playlists',
    name: 'Playlists',
    icon: playlistsIcon,
    requiredPermission: StaffAction.CREATE_PLAYLIST
  },
  {
    path: 'team',
    name: 'Staff Management',
    icon: teamIcon,
    requiredPermission: StaffAction.EDIT_STAFF_USER
  }
]

export default function Sidebar(props: {
  churchName: string;
  isMobile: boolean
}) {
  const [isOpen, setIsOpen] = useState(false);

  const sidebarContent = <SidebarContent churchName={props.churchName} closeSidebar={() => {
    setIsOpen(false)
  }} />

  return props.isMobile ? <>
    <header className='sidebar-header'>
      <a onClick={(e) => {
        e.preventDefault();

        setIsOpen(true)
      }}>
        <img src={sidebarIcon} />
      </a>
      <h1>Salt Church Hub</h1>
    </header>

    <Menu isOpen={isOpen} onStateChange={(state) => setIsOpen(state.isOpen)} className='sidebar' styles={{
      bmBurgerButton: {
        display: 'none'
      }
    }}>
      {sidebarContent}
    </Menu>
  </> : <div className='sidebar sidebar-div'>
    {sidebarContent}
  </div>
}

function SidebarContent(props: {
  churchName: string,
  closeSidebar: () => void
}) {
  const theme = useDarkMode();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const path = location.pathname;
  let pathComponents = path.split('/');
  let route = pathComponents[1];

  if(route === "") {
    route = "home"
  }

  return <>
    <img src={theme === Theme.LIGHT ? logo : logoDark}
      draggable={false}
      alt="Salt Icon"
      className='logo' />

    <p className='greeting'>👋 Hi, {auth.user?.name}!</p>
    <p className='churchName'>{props.churchName}</p>

    <div className='divider' />

    <ul>
      {routes.map((r, i) => {
        if (r.requiredPermission && !auth.hasPermission(r.requiredPermission)) {
          return null;
        }
        
        return <li key={i} className={route == r.path ? 'selected' : ''}>
        <a href={`/${r.path}`} onClick={(e) => {
          e.preventDefault();

          navigate(`/${r.path}`);
          props.closeSidebar();
        }}>
          <img src={r.icon} />
          <p>{r.name}</p>
        </a>
      </li>
      })}
    </ul>

    <div className='divider' />

    <ul>
      <li>
        <a href="/logout" onClick={(e) => {
          e.preventDefault();

          auth.signOut(() => navigate("/"))
          props.closeSidebar();
        }}>
          <p style={{ marginRight: 8 }}>Sign Out</p>
          <img src={rightArrowIcon} />
        </a>
      </li>
    </ul>
  </>
}
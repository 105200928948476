import Modal, { ModalButtonStyle } from '@components/Modal'

import './index.scss'

export default function DeleteTeamMemberModal(props: {
  user: any,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void
}) {
  if (props.user === undefined) return null;

  return <Modal id="delete-team-member-modal"
    isOpen={props.isOpen}
    headerTitle={`Delete ${props.user.name} from Salt?`}
    footerButtons={[
      {
        title: `Delete ${props.user.name}`,
        style: ModalButtonStyle.FILLED,
        onClick: () => {

        }
      },
      {
        title: `Cancel`,
        style: ModalButtonStyle.OUTLINE,
        onClick: () => {
          props.setIsOpen(false)
        }
      }
    ]}>
    <p>Are you sure you want to delete {props.user.name}'s Salt Church Hub account? This will prevent them from being able to access Salt Church hub.</p>
  </Modal>
}
import './index.scss'

import logo from '@image/churchhub-logo.png'

export default function WelcomePage() {
  return (
    <div className='welcome'>
      <img src={logo} alt="Church Hub logo" />
      <h1>👋 Welcome to Church Hub!</h1>
      <p>Church Hub is designed with care to be your church's toolbox.</p>

      <span>
        <a href='https://saltplatform.com/support' target='_blank' rel='noopener noreferrer'>Get Support</a>
        <a href='mailto:jordan@saltplatform.com?subject=Salt%20Feature%20Request' target='_blank' rel='noopener noreferrer'>Request a Feature</a>
      </span>
    </div>
  )
}
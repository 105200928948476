import NetworkController, { HTTPMethod, HTTPRequest, NetworkEndpoint } from "@data/controller/NetworkController";
import { ChurchStaff, permissionMap, StaffAction } from "@data/interfaces/church.interfaces";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthTokenProvider from "./AuthTokenProvider";

interface LoginResponse {
  staff: ChurchStaff
  churchId: string
  accessToken: string
  refreshToken: string
}

interface AuthContextType {
  user?: ChurchStaff;
  churchId?: string;
  hasPermission: (permission: StaffAction) => boolean;
  signIn: (email: string, password: string) => Promise<ChurchStaff>;
  signOut: (callback: VoidFunction) => void;
  resetPassword: (token: string, newPassword: string) => Promise<boolean>;
  requestPasswordReset: (email: string) => Promise<string>;
  consumeRefreshToken: () => Promise<boolean>;
}

let AuthContext = createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  let cachedUser;

  if (localStorage.getItem("user") !== null) {
    cachedUser = JSON.parse(localStorage.getItem("user")!) as ChurchStaff;
  }

  let [user, setUser] = useState<ChurchStaff | undefined>(cachedUser);
  let [churchId, setChurchId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user === undefined) return;
    localStorage.setItem("user", JSON.stringify(user));
  }, [user, churchId]);

  const navigate = useNavigate();

  let signIn = async (email: string, password: string) => {
    const response = await NetworkController.performRequest<LoginResponse>(
      new HTTPRequest(HTTPMethod.POST, NetworkEndpoint.STAFF_LOGIN, {
        email, password
      }),
      consumeRefreshToken
    );

    if (response.error !== undefined || response.body === undefined) {
      throw new Error(response.error || "Something went wrong while communicating with Salt systems.");
    }

    const { accessToken, refreshToken, staff, churchId } = response.body;
    
    const authTokenProvider = AuthTokenProvider.getInstance();
    authTokenProvider.setAccessToken(accessToken)
    authTokenProvider.setRefreshToken(refreshToken);

    setUser(staff);
    setChurchId(churchId);

    return staff;
  };

  let resetPassword = async (token: string, password: string) => {
    const response = await NetworkController.performRequest<{
      success: boolean
    }>(
      new HTTPRequest(HTTPMethod.POST, NetworkEndpoint.STAFF_RESET_PASSWORD, {
        token, password
      }),
      consumeRefreshToken
    );

    if (response.error !== undefined || response.body === undefined) {
      throw new Error(response.error || "Something went wrong while communicating with Salt systems.");
    }

    return response.body.success;
  }

  let requestPasswordReset = async (email: string) => {
    const response = await NetworkController.performRequest<{
      message: string
    }>(
      new HTTPRequest(HTTPMethod.POST, NetworkEndpoint.STAFF_REQUEST_RESET_PASSWORD, {
        email
      }),
      consumeRefreshToken
    );

    if (response.error !== undefined || response.body === undefined) {
      throw new Error(response.error || "Something went wrong while communicating with Salt systems.");
    }

    return response.body.message;
  }

  let signOut = (callback: VoidFunction) => {
    const authTokenProvider = AuthTokenProvider.getInstance();
    authTokenProvider.setAccessToken(null);
    authTokenProvider.setRefreshToken(null);

    setUser(undefined);
    setChurchId(undefined);

    localStorage.removeItem("user");

    callback();
  };

  let consumeRefreshToken = async () => {
    const authTokenProvider = AuthTokenProvider.getInstance();
    const existingRefreshToken = authTokenProvider.getRefreshToken();

    if (existingRefreshToken === null || authTokenProvider.isTokenExpired(existingRefreshToken)) {
      signOut(() => {
        navigate("/")
      });

      return false;
    }

    const response = await NetworkController.performRequest<{
      accessToken: string,
      refreshToken: string,
      user: ChurchStaff,
      churchId: string
    }>(
      new HTTPRequest(HTTPMethod.POST, NetworkEndpoint.STAFF_USE_REFRESH_TOKEN, {
        refreshToken: existingRefreshToken
      }),
      async () => {
        return false
      },
      false
    );

    if (response.error || !response.body) {
      signOut(() => {
        navigate("/")
      });
      return false;
    }

    const { user, churchId, accessToken, refreshToken } = response.body;

    authTokenProvider.setAccessToken(accessToken);
    authTokenProvider.setRefreshToken(refreshToken);

    setUser(user);
    setChurchId(churchId);

    return true;
  }

  let hasPermission = (permission: StaffAction) => {
    if (user === undefined) return false;
    
    const validRoles = permissionMap[permission];
      return validRoles.filter(validRole => {
        return user?.roles.includes(validRole);
      }).length > 0;
  }

  let value = { user, signIn, signOut, resetPassword, requestPasswordReset, churchId, consumeRefreshToken, hasPermission };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
import { useEffect, useState } from "react";

export enum Theme {
  LIGHT,
  DARK
}

export default function useDarkMode() {
  const [mode, setMode] = useState<"light" | "dark" | undefined>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" :"light"
  );

  useEffect(() => {
    const modeMe = (e: any) => {
      setMode(e.matches ? "dark" : "light");
    }  
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', modeMe);
    return window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', modeMe);
  }, []);

  return mode === "light" ? Theme.LIGHT : Theme.DARK;
}
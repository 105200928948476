import axios from 'axios';
import AuthTokenProvider from '@auth/AuthTokenProvider'

export enum NetworkEndpoint {
  STAFF_LOGIN = "/auth/staff/email/sign-in",
  STAFF_USE_REFRESH_TOKEN = "/auth/staff/refresh-token",
  STAFF_RESET_PASSWORD = "/auth/staff/email/reset-password",
  STAFF_REQUEST_RESET_PASSWORD = "/auth/staff/email/request-reset-password",
}

export interface SignedPostInstructions {
  url: string;
  fields: Record<string, string>;
  cdnURL?: string;
}

export default class NetworkController {
  static async performRequest<T>(
    request: HTTPRequest,
    consumeRefreshToken: () => Promise<boolean>,
    useRefreshTokenIfNeeded = true
  ): Promise<HTTPResponse<T>> {
    try {
      if (request.endpoint !== NetworkEndpoint.STAFF_USE_REFRESH_TOKEN &&
        request.endpoint !== NetworkEndpoint.STAFF_LOGIN &&
        request.endpoint !== NetworkEndpoint.STAFF_RESET_PASSWORD &&
        request.endpoint !== NetworkEndpoint.STAFF_REQUEST_RESET_PASSWORD) {
        const tokenProvider = AuthTokenProvider.getInstance();
        let accessToken = tokenProvider.getAccessToken();

        if (accessToken !== null) {
          if (tokenProvider.isTokenExpired(accessToken)) {
            const success = useRefreshTokenIfNeeded ? await consumeRefreshToken() : false;
            if (!success) {
              throw new Error("Invalid session. Please login again.");
            }

            accessToken = tokenProvider.getAccessToken();
          }

          request.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }

      const result = await axios({
        method: request.method,
        url: process.env.REACT_APP_SALT_BACKEND_URL + request.endpoint,
        data: request.body || {},
        headers: request.headers
      });

      return {
        body: result.data as T
      }
    } catch (error: any) {
      if (request.endpoint !== NetworkEndpoint.STAFF_LOGIN &&
        request.endpoint !== NetworkEndpoint.STAFF_USE_REFRESH_TOKEN &&
        useRefreshTokenIfNeeded &&
        error.response?.status === 403
      ) {
        const success = await consumeRefreshToken();
        if (success) {
          // Re-run request now
          return await this.performRequest(request, consumeRefreshToken, false);
        }
      }

      let errorMessage = "Something went wrong. Please try again later.";

      if (error.hasOwnProperty('response') &&
        error.response !== undefined &&
        error.response.hasOwnProperty('data')
      ) {
        const { data } = error.response;
        if (data.hasOwnProperty('error')) {
          errorMessage = data.error;
        } else if (data.hasOwnProperty('errorMessage')) {
          errorMessage = data.errorMessage;
        }
      }

      return {
        error: errorMessage,
        extra: error?.response?.data?.extra
      }
    }
  }

  static async performSignedPost(instructions: SignedPostInstructions, data: any, mimeType: string): Promise<boolean> {
    const formData = new FormData();

    for (const field in instructions.fields) {
      formData.append(field, instructions.fields[field]);
    }

    // This must be last! Thanks AWS D:
    formData.append("file", data);

    try {
      const result = await axios.post(instructions.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      return result.status >= 200 && result.status <= 299;
    } catch (error: any) {
      console.log(error.response);

      return false;
    }
  }
}

export class HTTPRequest {
  headers: Record<string, string>
  method: HTTPMethod
  endpoint: NetworkEndpoint | string
  body?: any

  constructor(
    method: HTTPMethod,
    endpoint: NetworkEndpoint | string,
    body?: any,
    headers?: Record<string, string>
  ) {
    this.headers = headers || {}
    this.method = method
    this.endpoint = endpoint
    this.body = body
  }
}

export interface HTTPResponse<T> {
  extra?: Record<string, boolean>
  error?: string
  body?: T
}

export enum HTTPMethod {
  GET = "get",
  POST = "post",
  PATCH = "patch",
  PUT = "put",
  DELETE = "delete"
}
export enum SupportedChurchVideoService {
  YOUTUBE = 'YOUTUBE',
  FACEBOOK = 'FACEBOOK'
}

export interface ChurchLink {
  name: string;
  url: string;
}

export interface Church {
  id: string
  name: string
  location: string
  /**
   * The URL for the image of the church's text-based logo
   */
  logoUrl?: string
  /**
   * The URL for the image used in Church Search
   */
  pictureUrl?: string
  givingUrl?: string
  links: ChurchLink[]
  ministries: ChurchMinistry[]
  timezone: string
  frozen: boolean
}

export enum StaffRole {
  ADMIN = "Admin",
  CHECK_IN_MANAGER = "Check-In Manager",
  CHECK_IN_VIEWER = "Check-In Viewer",
  MINISTRY_MANAGER = "Ministry Manager",
  VIDEO_MANAGER = "Video Manager",
  DEVO_MANAGER = "Devo Manager",
  LIBRARY_MANAGER = "Library Manager"
}

export const allStaffRoles = [
  StaffRole.ADMIN,
  StaffRole.CHECK_IN_MANAGER,
  StaffRole.CHECK_IN_VIEWER,
  StaffRole.MINISTRY_MANAGER,
  // StaffRole.NEWSLETTER_MANAGER
]

export enum StaffAction {
  VIEW_CHECK_IN_STATS,
  MANAGE_CHECK_IN_REGIONS,
  DELETE_STAFF_USER,
  CREATE_STAFF_USER,
  EDIT_STAFF_USER,
  EDIT_CHURCH_METADATA,
  UPDATE_CONNECTED_SERVICE_CREDENTIALS,
  UPLOAD_VIDEO,
  EDIT_MINISTRY_DATA,
  CREATE_MINISTRY_DATA,
  DELETE_MINISTRY_DATA,
  CREATE_DAILY_DEVOTIONAL,

  CREATE_VIDEO_SERIES,
  EDIT_VIDEO_SERIES,
  DELETE_VIDEO_SERIES,
  CREATE_VIDEO_IN_SERIES,
  EDIT_VIDEO_IN_SERIES,
  DELETE_VIDEO_IN_SERIES,

  CREATE_PLAYLIST,
  EDIT_PLAYLIST,
  DELETE_PLAYLIST
}

/**
 * This is where staff allowed/denied actions can be configured.
 */
export const permissionMap = {
  [StaffAction.VIEW_CHECK_IN_STATS]: [StaffRole.ADMIN, StaffRole.CHECK_IN_MANAGER, StaffRole.CHECK_IN_VIEWER],
  [StaffAction.MANAGE_CHECK_IN_REGIONS]: [StaffRole.ADMIN, StaffRole.CHECK_IN_MANAGER],
  [StaffAction.DELETE_STAFF_USER]: [StaffRole.ADMIN],
  [StaffAction.EDIT_STAFF_USER]: [StaffRole.ADMIN],
  [StaffAction.CREATE_STAFF_USER]: [StaffRole.ADMIN],
  [StaffAction.EDIT_CHURCH_METADATA]: [StaffRole.ADMIN],
  [StaffAction.UPDATE_CONNECTED_SERVICE_CREDENTIALS]: [StaffRole.ADMIN],
  [StaffAction.UPLOAD_VIDEO]: [StaffRole.VIDEO_MANAGER, StaffRole.ADMIN],
  [StaffAction.EDIT_MINISTRY_DATA]: [StaffRole.MINISTRY_MANAGER, StaffRole.ADMIN],
  [StaffAction.CREATE_MINISTRY_DATA]: [StaffRole.MINISTRY_MANAGER, StaffRole.ADMIN],
  [StaffAction.DELETE_MINISTRY_DATA]: [StaffRole.MINISTRY_MANAGER, StaffRole.ADMIN],
  [StaffAction.CREATE_DAILY_DEVOTIONAL]: [StaffRole.ADMIN, StaffRole.DEVO_MANAGER],
  
  [StaffAction.CREATE_VIDEO_SERIES]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.EDIT_VIDEO_SERIES]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.DELETE_VIDEO_SERIES]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.CREATE_VIDEO_IN_SERIES]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.EDIT_VIDEO_IN_SERIES]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.DELETE_VIDEO_IN_SERIES]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],

  [StaffAction.CREATE_PLAYLIST]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.EDIT_PLAYLIST]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER],
  [StaffAction.DELETE_PLAYLIST]: [StaffRole.ADMIN, StaffRole.LIBRARY_MANAGER]
}

export interface ChurchStaff {
  id: string
  email: string
  name: string
  roles: StaffRole[]
}

export interface ChurchMinistry {
  name: string
  subministries: string[]
}

export {}
import { useEffect, useRef, useState } from "react";

import TeamMemberModal from './modal/TeamMemberModal'
import DeleteTeamMemberModal from './modal/DeleteTeamMemberModal'
import PageHeader from "@components/PageHeader";

import './index.scss'

import plusIcon from '@icon/plus.svg'
import pencilIcon from '@icon/pencil.svg'
import trashIcon from '@icon/trash.svg'

import { useChurchDataProvider } from "@data/ChurchDataProvider";
import { ChurchStaff } from "@data/interfaces/church.interfaces";

export default function TeamManager(props: {}) {
  const [showingMemberModal, setShowingMemberModal] = useState(false);
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any | undefined>(undefined);

  const churchData = useChurchDataProvider();
  const churchDataRef = useRef(churchData);

  useEffect(() => {
    churchDataRef.current.reloadChurch();
  }, [churchDataRef]);

  return <div className="team-manager">
    <PageHeader title="Staff Management" showBackButton={false} links={[
      {
        icon: plusIcon,
        title: "Add Staff Member",
        onClick: () => {
          setShowingMemberModal(true);
        }
      }
    ]} />

    <p className="mobile-warning">We recommend using a larger device for Staff Management.</p>

    <TeamMemberModal isOpen={showingMemberModal}
      setIsOpen={(isOpen) => {
        setShowingMemberModal(isOpen);
        if (!isOpen) {
          setTimeout(() => {
            setSelectedUser(undefined);
          }, 200);
        }
      }}
      user={selectedUser} />

    <DeleteTeamMemberModal user={selectedUser}
      isOpen={showDeleteMemberModal}
      setIsOpen={(isOpen) => {
        setShowDeleteMemberModal(isOpen);
        if (!isOpen) {
          setTimeout(() => {
            setSelectedUser(undefined);
          }, 200);
        }
      }} />

    <div className="card-holder">
      {churchData.staff.sort((one: any, two: any) => {
        return one.name < two.name ? -1 : 1;
      }).map((user: ChurchStaff) => {
        return <StaffCard user={user}
          key={user.id}
          onEditClicked={() => {
            setSelectedUser(user);
            setShowingMemberModal(true);
          }}
          onDeleteClicked={() => {
            setSelectedUser(user);
            setShowDeleteMemberModal(true);
          }} />
      })}
    </div>
  </div>
}

function StaffCard(props: {
  user: ChurchStaff,
  onEditClicked: () => void,
  onDeleteClicked: () => void
}) {
  return <div className="card">
    <h1>{props.user.name}</h1>
    <a href={`mailto:${props.user.email}`}>{props.user.email}</a>
    {props.user.roles.length === 0 ?
      <p>No Roles.</p> :
      <p>Roles: {(props.user.roles).map((role: any, idx: number) => {
        return <span key={role}>{role}{idx === props.user.roles.length - 1 ? "" : ", "}</span>
      })}</p>
    }
    <div className="divider" />
    <span>
      <a href="#edit" onClick={(e) => {
        e.preventDefault();
        props.onEditClicked();
      }}>
        <img src={pencilIcon} />
        <span>Edit</span>
      </a>
      <a href="#delete" onClick={(e) => {
        e.preventDefault();
        props.onDeleteClicked();
      }}>
        <img src={trashIcon} />
        <span>Delete</span>
      </a>
    </span>
  </div>
}
import jwt from 'jsonwebtoken';

export default class AuthTokenProvider {
  private static instance: AuthTokenProvider;
  private accessToken?: string;

  private constructor() { }

  public static getInstance(): AuthTokenProvider {
    if (!this.instance) {
      this.instance = new AuthTokenProvider();
    }

    return this.instance;
  }

  // Methods

  public getRefreshToken(): string | null {
    return localStorage.getItem("refreshToken");
  }

  public hasRefreshToken(): boolean {
    return this.getRefreshToken() != null;
  }

  public setRefreshToken(newToken: string | null) {
    if (newToken === null) {
      return localStorage.removeItem("refreshToken");
    }

    localStorage.setItem("refreshToken", newToken);
  }

  public getAccessToken(): string | null {
    return this.accessToken || null;
  }

  public setAccessToken(newToken: string | null) {
    this.accessToken = newToken === null ? undefined : newToken;
  }

  public isTokenExpired(token: string | null) {
    if (token === null) return true;

    const payload = jwt.decode(token);
    return typeof payload !== 'object' || (payload?.exp ?? 0) < Date.now() / 1000;
  }
}
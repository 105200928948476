import { useAuth } from '@auth/AuthProvider';
import Modal, { ModalButtonStyle } from '@components/Modal'
import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController';
import ChurchMinistryData from '@data/interfaces/ministrydata.interface';
import { useState } from 'react';

import './index.scss'

export default function DeleteServicePlanModal(props: {
  churchId?: string,
  plan: ChurchMinistryData | undefined,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void
}) {
  const auth = useAuth();

  const [deleting, setDeleting] = useState(false);

  if (props.plan === undefined) return null;

  return <Modal id="delete-service-plan-modal"
    isOpen={props.isOpen}
    headerTitle={`Delete Service Plan`}
    footerButtons={[
      {
        title: `Delete`,
        style: ModalButtonStyle.FILLED,
        enabled: !deleting,
        onClick: async () => {
          setDeleting(true);

          try {
            await NetworkController.performRequest<ChurchMinistryData>(
              new HTTPRequest(HTTPMethod.DELETE, `/church/${props.churchId}/ministry-data/${props.plan?.ministry}/${props.plan?.id}`),
              auth.consumeRefreshToken
            );

            alert("Deleted!")

            props.setIsOpen(false)
          } catch (error) {
            alert("Failed to delete: " + error);
          }

          setDeleting(false);
        }
      },
      {
        title: `Cancel`,
        style: ModalButtonStyle.OUTLINE,
        enabled: !deleting,
        onClick: () => {
          props.setIsOpen(false)
        }
      }
    ]}>
    <p>Are you sure you want to delete this service plan?</p>
  </Modal>
}
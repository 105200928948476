import useDarkMode, { Theme } from "@hooks/useDarkMode";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import './index.scss';

import logo from '@image/HeaderLogo.png'
import logoDark from '@image/HeaderLogo-dark.png'
import { useState } from "react";
import { useAuth } from "@auth/AuthProvider";

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const auth = useAuth();

  const type = searchParams.get("type");
  const token = searchParams.get("token");

  const theme = useDarkMode();

  const [error, setError] = useState("");
  const [performingAction, setPerformingAction] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  if (!token) {
    return <Navigate to="/login" />
  }

  const handleSubmit = async () => {
    if (newPassword !== newPasswordConfirm) {
      return setError("Your passwords do not match");
    }

    if (newPassword.match(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/) !== null) {
      return setError("Your password must be at least 8 characters long, contain a lowercase and uppercase letter, at least one number, and a special character.");
    }

    setPerformingAction(true);
    setError("");
    
    try {
      const success = await auth.resetPassword(token, newPassword);
      if (success) {
        alert("Your password has been reset. Please login with your new password.");
        navigate("/login");
      } else {
        setError("Failed to reset your password. Please try again later.");
      }
    } catch (error: any) {
      setError(error.message);
    }

    setPerformingAction(false);
  }

  return <div className="container reset-password-page">
    <img src={theme === Theme.LIGHT ? logo : logoDark}
      draggable={false}
      alt="Salt Logo" />

    {error !== "" ? <p className="error">{error}</p> : ""}

    <p style={{
      textAlign: 'center'
    }}>Use this form to {type === 'initial' ? 'set' : 'reset'} your Salt Church Hub password.</p>

    <input type="password"
      placeholder="New Password"
      value={newPassword}
      disabled={performingAction}
      onChange={(e) => {
        setNewPassword(e.target.value);
        setError("");
      }} />
    <input type="password"
      placeholder="New Password (Confirm)"
      value={newPasswordConfirm}
      disabled={performingAction}
      onChange={(e) => {
        setNewPasswordConfirm(e.target.value);
        setError("");
      }} />

    <button className={`reset ${performingAction ? "disabled" : ""}`}
      disabled={performingAction}
      onClick={handleSubmit}>{type === 'initial' ? 'Set' : 'Reset'} Password</button>

    <button className="login"
      disabled={performingAction}
      onClick={() => navigate("/")}>Login Instead</button>
  </div>
}
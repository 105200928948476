import './index.scss';
import Modal from 'react-modal';

interface ModalButton {
  title: string
  style: ModalButtonStyle,
  enabled?: boolean
  onClick: () => void
}

export enum ModalButtonStyle {
  FILLED = "filled",
  OUTLINE = "outline"
}

export default function ModalComponent(props: {
  id: string,
  headerTitle?: string,
  footerButtons: ModalButton[],
  isOpen: boolean,
  children: React.ReactNode
}) {
  return <Modal isOpen={props.isOpen}
    id={props.id}
    closeTimeoutMS={200}
    className="modal">
    <div className="content-holder">
      {props.headerTitle !== undefined ? <div className="header">
        <h2>{props.headerTitle}</h2>
      </div> : null}

      <div className="modal-content">
        {props.children}
      </div>

      <footer style={props.footerButtons.length === 1 ? {
        gridTemplateColumns: '1fr'
      } : {}}>
        {props.footerButtons.map((button) => {
          return <button key={button.title}
            className={`button-${button.style} ${!(button.enabled ?? true) ? 'disabled' : ''}`}
            onClick={e => {
              e.preventDefault();
              button.onClick();
            }}>
            {button.title}
          </button>
        })}
      </footer>
    </div>
  </Modal>
}
import { useEffect, useState } from 'react'
import Modal, { ModalButtonStyle } from '@components/Modal'
import './index.scss'
import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController';
import { ChurchStaff, StaffRole, allStaffRoles } from '@data/interfaces/church.interfaces';
import { useAuth } from '@auth/AuthProvider';
import { useChurchDataProvider } from '@data/ChurchDataProvider';

export default function TeamMemberModal(props: {
  user?: ChurchStaff,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void
}) {
  const auth = useAuth();
  const churchData = useChurchDataProvider();

  const [name, setName] = useState(props.user?.name || "");
  const [email, setEmail] = useState(props.user?.email || "");
  const [roles, setRoles] = useState<StaffRole[]>(props.user?.roles || []);

  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    if (props.isOpen && props.user !== undefined) {
      setName(props.user.name);
      setEmail(props.user.email);
      setRoles(props.user.roles);
    }

    if (!props.isOpen) {
      setTimeout(() => {
        setName("");
        setEmail("");
        setRoles([]);
      }, 200);
    }
  }, [props.isOpen, props.user]);

  return <Modal id="team-member-modal"
    isOpen={props.isOpen}
    headerTitle={props.user === undefined ? "New Team Member" : `Edit ${props.user.name}`}
    footerButtons={[
      {
        title: props.user === undefined ? "Invite Team Member" : "Save Changes",
        style: ModalButtonStyle.FILLED,
        enabled: !isSaving,
        onClick: async () => {
          setSaving(true);

          try {
            // make request to create or update user
            const payload = {
              email: email,
              name: name,
              roles: roles.map(role => role.toString())
            }

            const response = await NetworkController.performRequest<ChurchStaff>(
              (props.user === undefined ?
                new HTTPRequest(HTTPMethod.POST, `/church/${churchData.church?.id}/staff`, payload) :
                new HTTPRequest(HTTPMethod.PATCH, `/church/${churchData.church?.id}/staff/${props.user.id}`, payload)),
              auth.consumeRefreshToken
            );

            if (response.body !== undefined) {
              props.setIsOpen(false);
            } else {
              console.error(response.error);
            }
          } catch (error) {
            console.error(error);
          }

          setSaving(false);
        }
      },
      {
        title: "Cancel",
        style: ModalButtonStyle.OUTLINE,
        onClick: () => {
          props.setIsOpen(false)
        }
      }
    ]}>
    <form>
      <div className="info-holder">
        <div className="field-container">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" value={name} onChange={(e) => {
            setName(e.target.value);
          }} />
        </div>

        <div className="field-container">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" value={email} onChange={(e) => {
            setEmail(e.target.value);
          }} />
        </div>
      </div>

      <div className="separator" />

      <h3>Roles</h3>

      <div className="roles-holder">
        <div className="checkboxes">
          {allStaffRoles.map((role, i) => {
            return <section key={role}>
              <input type="checkbox" checked={roles.includes(role)} onChange={(e) => {
                e.preventDefault();

                if (e.target.checked) {
                  setRoles([...roles, role]);
                } else {
                  setRoles(roles.filter(role => role !== role));
                }

                console.log(roles);
              }} />
              <div>{role}</div>
            </section>
          })}
        </div>

        {/* <a href="#see-permissions">See Permissions</a> */}
      </div>

      <div className="separator" />
    </form>
  </Modal >
}
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import AuthTokenProvider from "./AuthTokenProvider";

export default function RequireAuth() {
  let auth = useAuth();
  let location = useLocation();

  if (AuthTokenProvider.getInstance().getAccessToken() == null && AuthTokenProvider.getInstance().getRefreshToken() != null) {
    auth.consumeRefreshToken();
    return <Outlet />
  }

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}